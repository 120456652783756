import { trackEvent, trackPageView } from '@finn-no/pulse-sdk';
import { safeParseISO } from 'shared/util/datetime-fns';

const extractNumberOfBedrooms = (objectData) =>
    // blank string, NaN or 0 should return null
    Number(objectData?.noOfBedrooms) || undefined;
const calculateAverageWeeklyPrice = (objectData) => {
    const currency = objectData?.pricing?.currency;
    const from = objectData?.pricing?.week?.from;
    const to = objectData?.pricing?.week?.to;

    if (currency === 'NOK' && Number.isInteger(from) && Number.isInteger(to)) {
        // Should only track whole numbers
        return Math.round((from + to) / 2);
    }
    return undefined;
};

const trackCommonClick = ({
    intent,
    name,
    objectName,
    objectData,
    targetData,
    fromDate,
    toDate,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
}) =>
    trackEvent({
        type: 'Click',
        intent,
        name,
        object: {
            id: name.replaceAll(' ', '_').toLowerCase(),
            type: 'UIElement',
            elementType: 'Button',
            name: objectName ?? undefined,
            hasExtendedProfile: false,
            items: [
                {
                    // TODO Should all these have pagetype ClassifiedAd?
                    type: 'ClassifiedAd',
                    id: objectData?.adId,
                    contentId: objectData?.adId?.toString(),
                    name: objectData?.heading,
                    category: 'travel > holiday rental',
                },
                {
                    type: 'Travel',
                    id: 'finntravel',
                    travelType: 'holiday_rental',
                    // Due to legacy definitions in Pulse, `destination` is used instead of `arrivalCountry`
                    destination: objectData?.location?.country ?? undefined,
                    arrivalCity: objectData?.location?.locality ?? undefined,
                    // TODO skj better to get a regular date as input instead of a string
                    departureDate: safeParseISO(fromDate)?.toISOString() ?? undefined,
                    returnDate: safeParseISO(toDate)?.toISOString() ?? undefined,
                    numberOfAdults,
                    numberOfChildren,
                    numberOfInfants,
                    numberOfRooms: extractNumberOfBedrooms(objectData),
                    totalPrice: calculateAverageWeeklyPrice(objectData),
                    provider: objectData?.organisation?.name ?? undefined,
                },
            ],
        },
        target: targetData,
        vertical: {
            name: 'travel',
            subVertical: 'holiday_rental',
        },
    });

export const trackBookingPageView = (adId) => {
    // Pulse tracker id: FHH_pv04 (see tracking section in README.md)
    trackPageView({
        object: {
            category: 'travel > holiday rental',
            type: 'Page',
            pageType: 'tool',
            items: [
                {
                    id: adId,
                    type: 'ClassifiedAd',
                    contentId: adId,
                    name: 'Booking page',
                    category: 'travel > holiday rental',
                },
            ],
        },
        vertical: {
            name: 'travel',
            subVertical: 'holiday_rental',
        },
    });
};

export const trackObjectPageView = (adId, brandName) => {
    // Pulse tracker id: FHH_pv02 (see tracking section in README.md)
    trackPageView({
        object: {
            category: 'travel > holiday rental',
            type: 'ClassifiedAd',
            contentId: adId,
            externalPartners: [
                {
                    brandName,
                },
            ],
        },
        vertical: {
            name: 'travel',
            subVertical: 'holiday_rental',
        },
    });
};

export const trackAdInsertionInfoPageView = (itemId) => {
    // Pulse tracker id: IF_pv22
    trackPageView({
        name: 'Ad insertion - travel-holiday-rental',
        object: {
            name: itemId ? 'Annonseredigering' : 'Annonseinnlegging',
            category: 'travel > holiday rental',
            type: 'Form',
            stepName: 'Info about FHH',
            action: itemId ? 'Edit ad' : 'Create ad',
            items: itemId
                ? [
                      {
                          type: 'ClassifiedAd',
                          id: itemId,
                          contentId: itemId,
                          name: 'n/a',
                          category: 'n/a',
                      },
                  ]
                : [],
        },
        vertical: {
            name: 'travel',
            subVertical: 'holiday_rental',
        },
    });
};

export const trackAdInsertionDetails = (itemId, kycNeeded, editing) => {
    // Pulse tracker id: IF_pv24
    trackPageView({
        name: 'Ad insertion - travel-holiday-rental',
        object: {
            name: editing ? 'Annonseredigering' : 'Annonseinnlegging',
            category: 'travel > holiday rental',
            type: 'Form',
            stepName: 'Pricing and specification of rental object',
            action: kycNeeded ? 'bankid not verified' : 'bankid verified',
            items: [
                {
                    type: 'ClassifiedAd',
                    id: itemId,
                    contentId: itemId,
                    name: 'n/a',
                    category: 'n/a',
                },
            ],
        },
        vertical: {
            name: 'travel',
            subVertical: 'holiday_rental',
        },
    });
};

export const trackAdInsertionPersonalInformation = (itemId, editing) => {
    // Pulse tracker id: IF_pv25
    trackPageView({
        name: 'Ad insertion - travel-holiday-rental',
        object: {
            name: editing ? 'Annonseredigering' : 'Annonseinnlegging',
            category: 'travel > holiday rental',
            type: 'Form',
            stepName: 'Summary - personal information and contact details',
            action: editing ? 'Edit ad' : 'Create ad',
            items: [
                {
                    type: 'ClassifiedAd',
                    id: itemId,
                    contentId: itemId,
                    name: 'n/a',
                    category: 'n/a',
                },
            ],
        },
        vertical: {
            name: 'travel',
            subVertical: 'holiday_rental',
        },
    });
};

export const trackOutPageView = () => {
    // Pulse tracker id: FHH_pv09
    trackPageView({
        object: {
            category: 'travel > holiday rental',
            type: 'Page',
            pageType: 'redirect',
        },
        vertical: {
            name: 'travel',
            subVertical: 'holiday_rental',
        },
    });
};

export const trackResultPage = (layout, props) => {
    const { data, filters } = props;

    const extractSelectionFilterList = (name, emptyOrStringOrList) => {
        if (typeof emptyOrStringOrList === 'string') {
            return [
                {
                    name,
                    value: emptyOrStringOrList,
                },
            ];
        }
        if (Array.isArray(emptyOrStringOrList)) {
            return emptyOrStringOrList.map((propertyType) => ({
                name,
                value: propertyType,
            }));
        }
        return [];
    };

    const landlordTypesFor = (isPrivate) => {
        if (isPrivate === true || isPrivate === 'true') {
            return [
                {
                    name: 'landlord_type',
                    value: 'private',
                },
            ];
        }
        if (isPrivate === false || isPrivate === 'false') {
            return [
                {
                    name: 'landlord_type',
                    value: 'business',
                },
            ];
        }
        return [
            {
                name: 'landlord_type',
                value: 'private',
            },
            {
                name: 'landlord_type',
                value: 'business',
            },
        ];
    };

    // TODO property type should be a name instead of a number
    const propertyTypes = extractSelectionFilterList('property_type', filters.property_types);
    const facilities = extractSelectionFilterList('facility', filters.facilities);
    const poisNearby = extractSelectionFilterList('poi_nearby', filters.pois_nearby);

    trackPageView({
        object: {
            category: 'travel > holiday rental',

            type: 'Listing',
            layout,
            filters: {
                // returns undefined instead of null or empty so Pulse removes it from the event it sends
                query: new URLSearchParams(window?.location.search).toString() || undefined,
            },
            sortingType: filters.sorting ?? 'best', // Default sorting type is 'Beste treff', i.e. 'best'
            numItems: data.stats?.numResults ?? -1,
            pageNumber: data.stats?.currentPage ?? -1,
            selectionFilters: [
                // TODO should any of these have a default value?
                {
                    name: 'booking_from',
                    value: filters.booking_from,
                },
                {
                    name: 'booking_to',
                    value: filters.booking_to,
                },
                {
                    name: 'no_of_beds_from',
                    value: filters.no_of_beds_from,
                },
                {
                    name: 'no_of_bedrooms_from',
                    value: filters.no_of_bedrooms_from,
                },
                {
                    name: 'is_direct_booking',
                    value: filters.is_direct_booking,
                },
                {
                    name: 'price_type',
                    value: filters.price_type,
                },
                {
                    name: 'price_from',
                    value: filters.price_from,
                },
                {
                    name: 'price_to',
                    value: filters.price_to,
                },
            ]
                .concat(propertyTypes)
                .concat(facilities)
                .concat(poisNearby)
                .concat(landlordTypesFor(filters.is_private))
                .filter(({ value }) => value !== undefined && value !== null)
                .map(({ name, value }) => ({ name, value: value.toString() })),
        },

        vertical: {
            name: 'travel',
            subVertical: 'holiday_rental',
        },
    });
};

// Pulse tracker id: FHH_ev04 (see tracking section in README.md)
export const trackAddBookingToCalendar = (objectData, fromDate, toDate) =>
    trackCommonClick({
        intent: 'Purchase',
        name: 'Add booking to calendar',
        objectData,
        fromDate,
        toDate,
    });

// Pulse tracker id: FHH_ev05 (see tracking section in README.md)
export const trackConfirmOrder = (objectData, orderData) =>
    trackCommonClick({
        intent: 'Purchase',
        name: 'Book vacation home',
        objectData,
        fromDate: orderData?.fromDate,
        toDate: orderData?.toDate,
        numberOfAdults: orderData?.adults,
        numberOfChildren: orderData?.children,
        numberOfInfants: orderData?.infants,
        targetData: {
            type: 'Confirmation',
            id: objectData.owner.url,
            name: 'Go to confirmation page',
            referralCommission: true,
            provider: objectData.organisation.name,
        },
    });

// Pulse tracker id: FHH_ev06 (see tracking section in README.md)
export const trackSendMessage = (data) =>
    trackCommonClick({
        intent: 'Send',
        name: 'Send message',
        ...data,
    });

// Pulse tracker id: FHH_ev07 (see tracking section in README.md)
export const trackCheckPriceAndAvailability = (data) =>
    trackCommonClick({
        intent: 'Open',
        name: 'Check price and availability',
        objectName: data.objectData.isImport ? 'import offer' : 'admin offer',
        ...data,
        targetData: {
            id: data.objectData.owner.deeplink ? data.objectData.owner.deeplink : data.objectData.owner.url,
            type: 'ExternalContent',
            name: 'Go to webpage',
            referralCommission: data.objectData.isImport ?? false,
            provider: data.objectData.organisation?.name ?? undefined,
        },
    });

export const trackClick = (pulseData) => trackEvent(pulseData);
