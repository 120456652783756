import cn from 'classnames';
import type { DateItem } from '../../utils/dates';
import type { CountryCodes } from '../../constants';
import { createMoney, getDisplayCurrency } from 'shared/models/money';

export interface CalendarDayProps {
    'aria-selected'?: boolean;
    className?: string;
    countryCode: CountryCodes;
    dateItem: DateItem;
    disabled?: boolean;
    displayedMonthNumber: number;
    onDaySelect: (dateItem: DateItem) => void;
}

const getWeekNumber = (date: Date): number => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.valueOf() - firstDayOfYear.valueOf()) / 86400000;

    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const CalendarDay = ({
    className,
    countryCode,
    dateItem,
    disabled,
    onDaySelect,
    displayedMonthNumber,
    ...otherProps
}: CalendarDayProps) => {
    const { date, data } = dateItem;
    const { isCurrentMonth, isExpired, isFirstDayOfMonth, isLastDayOfMonth, isToday, isWeekend } = dateItem.states ?? {};
    const dayOfMonth = date.getDate();
    const month = date.getMonth();
    const isDisabled = typeof disabled !== 'undefined' ? disabled : isExpired;
    const moneyItem = data?.content && createMoney({ currency: 'NOK', value: data?.content });
    const content = moneyItem && getDisplayCurrency(moneyItem);
    const weekNumber = getWeekNumber(date);
    const title = data?.title;

    return (
        <button
            className={cn('CalendarDay', className, {
                'CalendarDay--isCurrentMonth': isCurrentMonth,
                'CalendarDay--isNotCurrentMonth': !isCurrentMonth,
                'CalendarDay--isWeekend': isWeekend,
                'CalendarDay--isExpired': isExpired,
                'CalendarDay--isFirstDayOfMonth': isFirstDayOfMonth,
                'CalendarDay--isLastDayOfMonth': isLastDayOfMonth,
                'CalendarDay--isToday': isToday,
                'CalendarDay--isNotDisplayedMonth': month !== displayedMonthNumber,
            })}
            aria-label={date.toDateString()}
            onClick={() => onDaySelect(dateItem)}
            disabled={isDisabled}
            type="button"
            role="button"
            data-week-number={weekNumber}
            {...otherProps}>
            <time className="CalendarDay-time" dateTime={date.toDateString()}>
                {dayOfMonth}
            </time>

            <span className="CalendarDay-title">{title}</span>
            {content && <span className="CalendarDay-content">{content}</span>}
        </button>
    );
};
