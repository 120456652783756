interface Props {
    className?: string;
    size?: number;
}

export const SpinnerIcon = ({ className, size = 22, ...rest }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="#09f"
        viewBox="0 0 32 32"
        className={className}
        // biome-ignore lint/a11y: required by several components and tests
        role="progressbar"
        data-testid="spinner-icon"
        {...rest}>
        <circle cx="16" cy="3" r="0">
            <animate
                attributeName="r"
                begin="0"
                calcMode="spline"
                dur="1s"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                repeatCount="indefinite"
                values="0;3;0;0"
            />
        </circle>
        <circle cx="16" cy="3" r="0" transform="rotate(45 16 16)">
            <animate
                attributeName="r"
                begin="0.125s"
                calcMode="spline"
                dur="1s"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                repeatCount="indefinite"
                values="0;3;0;0"
            />
        </circle>
        <circle cx="16" cy="3" r="0" transform="rotate(90 16 16)">
            <animate
                attributeName="r"
                begin="0.25s"
                calcMode="spline"
                dur="1s"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                repeatCount="indefinite"
                values="0;3;0;0"
            />
        </circle>
        <circle cx="16" cy="3" r="0" transform="rotate(135 16 16)">
            <animate
                attributeName="r"
                begin="0.375s"
                calcMode="spline"
                dur="1s"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                repeatCount="indefinite"
                values="0;3;0;0"
            />
        </circle>
        <circle cx="16" cy="3" r="0" transform="rotate(180 16 16)">
            <animate
                attributeName="r"
                begin="0.5s"
                calcMode="spline"
                dur="1s"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                repeatCount="indefinite"
                values="0;3;0;0"
            />
        </circle>
        <circle cx="16" cy="3" r="0" transform="rotate(225 16 16)">
            <animate
                attributeName="r"
                begin="0.625s"
                calcMode="spline"
                dur="1s"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                repeatCount="indefinite"
                values="0;3;0;0"
            />
        </circle>
        <circle cx="16" cy="3" r="0" transform="rotate(270 16 16)">
            <animate
                attributeName="r"
                begin="0.75s"
                calcMode="spline"
                dur="1s"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                repeatCount="indefinite"
                values="0;3;0;0"
            />
        </circle>
        <circle cx="16" cy="3" r="0" transform="rotate(315 16 16)">
            <animate
                attributeName="r"
                begin="0.875s"
                calcMode="spline"
                dur="1s"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                repeatCount="indefinite"
                values="0;3;0;0"
            />
        </circle>
        <circle cx="16" cy="3" r="0" transform="rotate(180 16 16)">
            <animate
                attributeName="r"
                begin="0.5s"
                calcMode="spline"
                dur="1s"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                repeatCount="indefinite"
                values="0;3;0;0"
            />
        </circle>
    </svg>
);
